<template>
  <div class="flex flex-col">
    <transition
      enter-active-class="miniFadeInTop"
      leave-active-class="miniFadeOutBottom"
      mode="out-in"
    >
      <div v-if="catsCount >= 0" class="mx-auto w-full md:w-auto">
        <div class="flex flex-col justify-center">
          <div class="relative mx-auto">
            <taster-box />
          </div>
          <transition
            enter-active-class="miniFadeInTop"
            leave-active-class="miniFadeOutBottom"
            mode="out-in"
          >
            <div
              key="cats-cards"
              class="my-8 w-full px-4 text-center text-xl font-semibold sm:px-8"
            >
              {{
                catsCount === 1 ? 'Is your cat around' : 'Are your cats around'
              }}?<br />
              we need some quick details...
            </div>
          </transition>

          <transition-group
            appear
            tag="div"
            class="mx-auto flex flex-col items-center justify-center space-y-4"
            enter-active-class="miniFadeInTop"
            leave-active-class="miniFadeOutBottom"
            mode="out-in"
          >
            <cat-card
              :id="`cat-card-${index}`"
              :key="'cat-' + index"
              v-for="(cat, index) in cats"
              :position="index"
              :cat="cat"
              :selectedCat="selectedCat"
              @selected-cat="selectCat"
              @remove-cat="removeCat"
              :numCats="catsLength"
            />
          </transition-group>
          <div
            class="mt-4 flex cursor-pointer items-center justify-center space-x-4 rounded-lg border-2 p-6 font-semibold text-gray-500 hover:cursor-pointer hover:bg-gray-100 sm:m-2 sm:mt-4"
            @click="addCat"
          >
            <font-awesome-icon :icon="['far', 'plus']" />
            <div>Add another cat</div>
          </div>
        </div>
        <div class="mt-2 rounded p-6 pb-2 text-center text-gray-700">
          Your price per month for
          <span class="font-semibold text-black"
            >{{ pouchesCount }} Pouches</span
          >
          <div class="py-2 pb-0 text-3xl font-semibold text-black">
            {{ subscriptionPrice.total }}
          </div>
          <!-- <div class="text-sm text-gray-500">
            (delivery calculated at checkout)
          </div> -->
        </div>
        <div v-if="catsLength > 0" class="core-area mx-auto mt-0 p-4">
          <form @submit.prevent="getPrice">
            <div v-if="!fromSubscribe">
              <div class="input-group">
                <label>What’s the email address of your chief feeder?</label>
                <input
                  v-model.lazy="email"
                  class="fs-exclude"
                  type="text"
                  placeholder="email@example.com"
                />
              </div>

              <label class="flex w-full flex-row items-start"
                ><input type="checkbox" v-model="marketing" class="mr-2 mt-1" />
                <span class="w-full pr-4 text-sm font-medium">
                  I consent for further information and occasional one-time
                  offers.
                </span>
              </label>
            </div>

            <div class="mt-6 flex items-center">
              <loading-button
                :is-loading="isLoading"
                :is-enabled="true"
                is-full
                class="w-full"
              >
                <div>
                  Next
                  <font-awesome-icon
                    :icon="['far', 'arrow-right-long']"
                    class="ml-2"
                  />
                </div>
              </loading-button>
            </div>
          </form>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import CatCard from '@/components/CatCard';
import LoadingButton from '@/components/LoadingButton';

import http from '@/http';
import _ from 'lodash';
import { isEmpty, pullAt, sumBy } from 'lodash/fp';
import utils from '@/utils';
import { mapGetters } from 'vuex';
import TasterBox from '../../../components/TasterBox.vue';
export default {
  metaInfo: {
    title: 'Cat & Kitten Food Subscriptions from The Cool Cat Club',
    meta: [
      {
        name: 'description',
        content:
          'Make the Perfect Monthly Cat & Kitten Food Packs for your Feline Friend with The Cool Cat Club’s wide range of Wet & Dry Cat Food Subscription Services.'
      }
    ]
  },
  components: {
    CatCard,
    LoadingButton,
    TasterBox
  },
  props: ['subscription'],
  data() {
    return {
      catTemplate: {
        name: '',
        gender: '',
        tribe: '',
        isKitten: false,
        active: true,
        samplebox: true,
        products: [],
        sampleboxtype: {
          dryfood: true,
          type: 'original'
        },
        foodAmount: 60
      },
      catsNo: 0,
      selectedCat: 0,
      cats: undefined,
      isLoading: false,
      email: '',
      marketing: true,
      fromSubscribe: this.$route.name === 'subscribe cats' ? true : false,
      subscriptionPrice: 0
    };
  },
  computed: {
    catsCount: function () {
      return parseInt(this.catsNo);
    },
    catsLength() {
      return this.cats ? this.cats.length : false;
    },
    pouchesCount() {
      return sumBy(function (o) {
        return o.foodAmount;
      }, this.cats);
    },

    catsClone() {
      // creat e a deep clone of the cats arrayif cats exists
      return this.cats ? JSON.parse(JSON.stringify(this.cats)) : [];
    },
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      isTaster: 'getIsTaster',
      tasterType: 'getTasterType',
      getTasterValue: 'getTasterValue'
    })
  },
  watch: {
    cats(val) {
      if (val) {
        utils.setStorage('cats', val, 'local');
      }
    },
    catsNo: function (newValue) {
      let self = this;
      let currentCats = this.cats ? this.cats.length : this.getCatsLength();
      let catsSelected = newValue;
      // clone the cat template and set it as a new constant
      // clone the cat template and set it as a new constant
      let catTemplate = JSON.parse(JSON.stringify(this.catTemplate));

      let diff = catsSelected - currentCats;
      for (var i = 0; i < diff; i++) {
        let catData;
        if (utils.getStorage('cats', 'local')) {
          catData = utils.getStorage('cats', 'local')[currentCats + i]
            ? utils.getStorage('cats', 'local')[currentCats + i]
            : catTemplate;
        } else {
          catData = catTemplate;
        }
        if (newValue != 4) {
          self.cats.push(catData);
        }
      }
    },
    catsClone: {
      deep: true,
      handler(value, oldValue) {
        if (value.length) {
          if (oldValue) {
            if (
              oldValue.some(
                (cat, index) => cat.foodAmount !== value[index].foodAmount
              )
            ) {
              this.getSubscriptionPrice();
            }
            if (value.length !== oldValue.length) {
              this.getSubscriptionPrice();
            }
          }
        }
        utils.setStorage('cats', value, 'local');
      }
    },
    marketing: {
      immediate: true,
      handler(value) {
        sessionStorage.setItem('marketing', value);
      }
    }
  },
  methods: {
    getSubscriptionPrice() {
      const data = {
        cats: []
      };

      this.cats.forEach((value) => {
        const increment = 5;
        data.cats.push({
          products: [
            {
              sku: 'W_PCH_CHK',
              quantity: value.foodAmount / increment
            }
          ]
        });
      });

      http
        .post('/subscription_product_preview', data)
        .then((response) => {
          this.subscriptionPrice = response.data.content[0];
        })
        .catch((err) => {
          throw Error(err.response.data.error.message);
        });
    },
    selectCat: function (val) {
      this.selectedCat = val;
      this.$nextTick(() => {
        const el = document.getElementById(`cat-card-${val}`);

        if (el) {
          el.scrollIntoView({ behavior: 'instant' });
        }
      });
    },
    removeCat: function (val) {
      this.cats = pullAt(val, this.cats);
    },
    addCat() {
      const cat = JSON.parse(JSON.stringify(this.catTemplate));
      cat.samplebox = true;
      this.cats.push(cat);

      this.selectedCat = this.selectedCat + 1;
      // get the div at the slectedCat index
      this.$nextTick(() => {
        const el = document.getElementById(`cat-card-${this.cats.length - 1}`);

        if (el) {
          el.scrollIntoView({ behavior: 'instant' });
        }
      });
    },
    catRequest: function () {
      let cats = [];
      _.forEach(this.cats, function (value) {
        cats.push(
          `${value.name} (${value.wetFood} Pouches / ${
            value.dryFood / 1000
          } Kg)`
        );
      });

      return cats;
    },
    getCats: function () {
      if (this.subscription && this.subscription.items.length > 0) {
        let cats = utils.getStorage('cats', 'local');

        _.forEach(cats, function (value) {
          value.active = true;
        });
        this.cats = cats;
        this.getCatsLength();
      } else {
        this.cats = !isEmpty(utils.getStorage('cats', 'local'))
          ? utils.getStorage('cats', 'local')
          : [JSON.parse(JSON.stringify(this.catTemplate))];
        this.cats.forEach((cat) => {
          if (!cat.foodAmount) {
            this.$set(cat, 'foodAmount', 60);
          }
        });
        this.getCatsLength();
      }
      this.getSubscriptionPrice();
    },
    getCatsLength: function () {
      if (this.subscription && this.subscription.items.length > 0) {
        let cats = this.subscription.items;
        this.catsNo = cats.length;
      } else {
        this.catsNo = this.cats.length;
      }
    },
    getPrice: function () {
      this.cats.forEach((c, i) => {
        if (isEmpty(c.name)) {
          c.name = 'Cool Cat ' + (i + 1);
        }
      });
      utils.setStorage('cats', this.cats, 'local');
      this.isLoading = true;
      let self = this;
      // Sends pricing plan requested

      this.$store.dispatch('sendMpEvent', {
        event: 'Signup - Added Cats',
        properties: {
          location: this.$route.name,
          noOfCats: `${this.catsLength} Cats}`,
          type: 'EVT'
        }
      });

      let items = [];
      _.forEach(this.cats, function (value, index) {
        value.active = index + 1 <= self.catsCount;
        items.push({
          dryFood: value.dryFood,
          wetFood: value.wetFood,
          active: value.active
        });
      });

      this.isLoading = false;

      sessionStorage.setItem('email', this.email);
      if (this.marketing) {
        this.saveEmail();
      }

      this.$store.dispatch('sendFbEvent', {
        type: 'track',
        name: 'AddToCart',
        data: {
          content_name: 'Taster Box',
          value: this.cats.length * this.getTasterValue,
          currency: 'GBP'
        }
      });

      // if (this.isTaster) {
      if (this.isLoggedIn) {
        this.$router
          .push({
            name: 'delivery'
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$router
          .push({
            name: 'create account'
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    hideEmail: function (email) {
      let str = email;
      str = str.split('');
      let finalArr = [];
      let len = str.indexOf('@');
      str.forEach((item, pos) => {
        pos >= 3 && pos <= len - 2
          ? finalArr.push('*')
          : finalArr.push(str[pos]);
      });
      return finalArr.join('');
    },
    saveEmail: function () {
      let data = {
        email: this.email
      };
      http
        .post('/em', data)
        .then(() => {
          this.$store.dispatch('sendMpEvent', {
            event: 'Email Captured',
            properties: {
              location: 'cats',
              email: this.hideEmail(this.email),
              type: 'EVT'
            }
          });
        })
        .catch(() => {});
    }
  },
  mounted() {
    this.getCats();
  }
};
</script>

<style lang="scss" scoped>
.core-area {
  max-width: 400px;
  width: auto;
}
</style>
